import { createSlice } from '@reduxjs/toolkit';
// utils
//
// import { dispatch } from '../store';
import {
  addParticipantIntoCard,
  createNoteConversation,
  getCurrentConversation,
  sendNote,
} from '../../services/PlaygroundService';
import { handleSendMessageToAssistant } from '../../_apis_/connections_llm';
import uuidv4 from '../../utils/uuidv4';
// import { setCurrentCompany } from './company';
// import { addPointsToUser } from '../../services/RatingsService';

// ----------------------------------------------------------------------

const initialStateConversation = () => ({
  id: '',
  messages: [],
  participants: [],
  type: 'ONE_TO_ONE',
  unreadCount: 0,
});

export const initialState = {
  isLoading: false,
  error: null,
  id: null,
  usersList: [],
  clientsList: [],
  currentCard: {},
  conversation: { id: '', messages: [], participants: [], type: 'ONE_TO_ONE', unreadCount: 0 },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET CURRENT CARD
    setCurrentCard(state, action) {
      state.currentCard = action.payload;
    },

    // SET USERS LIST
    setUsersList(state, action) {
      state.usersList = action.payload;
    },

    // SET USERS LIST
    setClientsList(state, action) {
      state.clientsList = action.payload;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;
      // console.log('getConversationSuccess conversation', conversation);
      const newConversation = {
        ...conversation,
        messages: conversation.messages || [],
      };
      state.conversation = newConversation;

      // if (conversation) {
      //   state.conversations.byId[conversation.id] = conversation;
      //   state.activeConversationId = conversation.id;
      //   if (!state.conversations.allIds.includes(conversation.id)) {
      //     state.conversations.allIds.push(conversation.id);
      //   }
      // } else {
      //   state.activeConversationId = null;
      // }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const { conversation, card } = action.payload;
      // console.log('onSendMessage conversation', conversation);
      const { messageId, message, contentType, attachments, createdAt, senderId } = conversation; // conversationId

      const newMessage = {
        ...(contentType === 'attachment' ? conversation.file : null),
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      const messages = state.conversation.messages || [];
      const newMessages = [...messages, newMessage];
      // state.conversation.messages = newMessages;

      const newConversation = {
        ...conversation,
        messages: newMessages,
      };

      state.conversation = newConversation;

      state.currentCard.conversation = newConversation;
      // console.log('newConversation xdddxd', newConversation);
      // setCard({ card, name: 'conversation', element: newConversation });
      // console.log('state.conversation', state.conversation.messages);
      // state.conversation.messages.push(newMessage); // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    // SET NOTE CONVERSATION
    setNoteConversation(state, action) {
      const { conversation } = action.payload;
      state.conversation = conversation;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;
export const { setCurrentCard, setUsersList, setClientsList, getConversationSuccess } = actions;

// ----------------------------------------------------------------------

// CONVERSATION

export function getConversation(companyId, conversationKey, senderId) {
  return async (dispatch) => {
    // se limpia
    dispatch(slice.actions.getConversationSuccess(initialState.conversation));
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/chat/conversation', {
      //   params: { conversationKey: 'sofia.funk' }, // params: { conversationKey },
      // });
      // console.log('conversations xd', response.data.conversation);
      // dispatch(slice.actions.getConversationSuccess(response.data.conversation));

      // let conversation = initialStateConversation(senderId); // .currentBoard;
      console.log('1');
      console.log('companyId', companyId);
      console.log('conversationKey', conversationKey);
      let conversation = await getCurrentConversation(companyId, conversationKey);
      console.log('c', conversation);
      if (!conversation) {
        console.log('3');
        conversation = await createNoteConversation(companyId, initialStateConversation(senderId), conversationKey);
        console.log('conversation creada', conversation);
      } else {
        console.log('4');
        conversation = await getCurrentConversation(companyId, conversationKey);
        console.log('conversation', conversation);
        if (!conversation) {
          console.log('5');
          conversation = await createNoteConversation(companyId, initialStateConversation(senderId), conversationKey);
        }
      }
      console.log('conversation XD', conversation);
      dispatch(slice.actions.getConversationSuccess(conversation));
    } catch (error) {
      console.error('error', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

const handleAddMessage = async ({ conversation, card, companyId, newMessages, newParticipant }) => {
  await sendNote(companyId, card.id, newMessages);

  // comentado porque ya se usa el listening
  // dispatch(slice.actions.onSendMessage({ conversation, card }));

  const participants = conversation?.participants || [];

  const participantIds = participants.map((el) => el.id);
  // const participantExists = participantIds.includes(profile.id);
  const participantExists = participantIds.includes(newParticipant.id);
  if (!participantExists) {
    const newParticipants = [...participants, newParticipant];
    // console.log('newParticipants', newParticipants);
    await addParticipantIntoCard(companyId, card.id, newParticipants);
  }
};

export function onSendMessage({
  conversation,
  card,
  companyId,
  profile,
  currentConnection,
  setIsLoadingAnswer,
  handleUpdateAssistansState,
}) {
  return async (dispatch) => {
    const { messageId, message, messages, contentType, attachments, createdAt, senderId } = conversation; // conversationId
    const newMessage = {
      ...(contentType === 'attachment' ? conversation.file : null),
      id: messageId,
      body: message,
      contentType,
      attachments,
      createdAt,
      senderId,
    };
    const newMessages = [...(messages || []), newMessage];

    const newParticipant = {
      address: profile.address || 'CR',
      avatar: profile.photoURL || '/static/user/user.png',
      email: profile.email || '',
      from: profile.phoneNumber || '',
      id: profile.id || '',
      uid: profile.id || '',
      lastActivity: new Date().toISOString(),
      name: `${profile.firstName} ${profile.lastName}`,
      phone: profile.phoneNumber || '',
      role: 'ux designer',
      status: 'online',
      username: `${profile.firstName || ''} ${profile.lastName || ''}`,
    };

    await handleAddMessage({ conversation, card, companyId, profile, newMessages, newParticipant });

    // GPT ////////////////////////////////////////////////////////
    const answerResult = await handleSendMessageToAssistant(
      companyId,
      card,
      currentConnection,
      message,
      handleUpdateAssistansState
    );
    // console.log('answerResult', answerResult);

    let messageBody = '';
    if (answerResult.code >= 200 && answerResult.code < 300) {
      messageBody = answerResult.answer;
    } else {
      const err = answerResult?.error?.data?.error || answerResult.error;
      messageBody = err?.message || err;
      // setIsLoadingAnswer(false);
      // return;
    }

    const createdAt2 = new Date().toISOString();
    const newAnswerMessage = {
      // ...(contentType === 'attachment' ? conversation.file : null),
      id: uuidv4(),
      body: messageBody,
      contentType: 'text',
      attachments: [],
      createdAt: createdAt2,
      senderId: card.id,
    };
    const newMessagesWithAnswer = [...newMessages, newAnswerMessage];

    const newParticipantAssistant = {
      address: profile.address || 'CR',
      avatar: profile.photoURL || '/static/user/user.png',
      email: profile.email || '',
      from: card.id || '',
      id: card.id || '',
      uid: card.id || '',
      lastActivity: createdAt2,
      name: card.assistantName,
      phone: card.id || '',
      role: 'asistente',
      status: 'online',
      username: card.assistantName,
    };

    await handleAddMessage({
      conversation,
      card,
      companyId,
      newMessages: newMessagesWithAnswer,
      newParticipant: newParticipantAssistant,
    });
    setIsLoadingAnswer(false);
  };
}

export function setNoteConversation({ conversation }) {
  return async (dispatch) => {
    dispatch(slice.actions.setNoteConversation({ conversation }));
  };
}
